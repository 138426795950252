import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
  message,
  Slider,
  InputNumber,
  Input,
  Cascader,
  Select,
  DatePicker,
  TimePicker,
  Tag,
  Pagination,
  Empty,
  Collapse,
  AutoComplete,
  Spin,
  Table,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import moment from "moment";
import qs from "qs";
import { debounce } from "lodash";
import CardHeader from "antd-mobile/lib/card/CardHeader";
import CardBody from "antd-mobile/lib/card/CardBody";
import { GET_RESUME_ALL, GET_RESUME_LIST } from "../../api/resume";
import {
  GET_HALL_LIST,
  ADD_HALL_COLLECT,
  DEL_HALL_COLLECT,
  POST_HALL_JOB,
} from "../../api/hall";
import { GET_PROVINCE, GET_CITY, GET_AREA, GET_TABLE } from "../../api/base";
const { Panel } = Collapse;
const { Option } = Select;
const CreateShopForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [all, setAll] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
    name: "",
  });

  useEffect(async () => {
    form.resetFields();
    if (visible) {
      // getAll();
      getList(pagination);
    }
  }, [visible]);

  const getAll = async () => {
    const {
      data: { data, code },
    } = await GET_RESUME_ALL();
    if (code == 200) {
      setAll(data);
    }
  };

  const getList = async (paginations) => {
    const {
      data: {
        code,
        data: { data, pageNo, pageSize, pageTotal, total },
      },
    } = await GET_RESUME_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data);
      setPagination({
        ...paginations,
        total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
    });
    console.log("Page: ", pages);
  };

  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "性别",
      dataIndex: "sex",
      key: "sex",
    },
    {
      title: "联系方式",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "年龄",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "意向职业",
      dataIndex: "occupation",
      key: "occupation",
    },
    {
      title: "意向城市",
      dataIndex: "cities",
      key: "cities",
    },
    {
      title: "学历",
      dataIndex: "education",
      key: "education",
    },
    {
      title: "求职状态",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "工作经验",
      dataIndex: "experience",
      key: "experience",
      align: "center",
    },
    {
      title: "期望薪资",
      dataIndex: "experience",
      key: "experience",
      align: "center",
      render: (text, data) =>
        (data.min_salary_id == 1 && data.max_salary_id == 1 && "面议") || (
          <div>
            {data.min_salary || "暂无"} ~ {data.max_salary || "暂无"}
          </div>
        ),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    // console.log(selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    form.setFieldsValue({
      resume_ids: [...selectedRowKeys],
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Modal
      getContainer={false}
      visible={visible}
      width={1150}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>推荐人才</h1>
          </div>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="resume_ids" label="选择简历" hidden>
                <Select mode="multiple" />
              </Form.Item>
              {/* <Form.Item name="resume_ids" label="选择简历">
                <Select
                  mode="multiple"
                  placeholder="请选择要推荐的简历"
                  options={all.map((item, key) => ({
                    label: item.name,
                    value: item.id,
                    key: key,
                    item: item,
                  }))}
                  style={{
                    width: "100%",
                  }}
                  // onChange={(e)=>{
                  //   console.log(e)
                  // }}
                />
              </Form.Item> */}
              <Form.Item name="interview_date_time" label="面试时间">
                <DatePicker showTime placeholder="请选择面试时间" />
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginBottom: "15px",
                }}
              >
                <Form
                  layout="inline"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  style={{ width: "100%" }}
                >
                  <Form.Item label={"姓名"}>
                    <Input
                      placeholder="请输入关键词"
                      allowClear
                      style={{ width: "150px" }}
                      onChange={(e) => {
                        setPagination({
                          ...pagination,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={"联系方式"}>
                    <Input
                      placeholder="请输入关键词"
                      allowClear
                      style={{ width: "150px" }}
                      onChange={(e) => {
                        setPagination({
                          ...pagination,
                          mobile: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={"意向职业"}>
                    <Input
                      placeholder="请输入关键词"
                      allowClear
                      style={{ width: "150px" }}
                      onChange={(e) => {
                        setPagination({
                          ...pagination,
                          occupation: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item label={"意向城市"}>
                    <Input
                      placeholder="请输入关键词"
                      allowClear
                      style={{ width: "150px" }}
                      onChange={(e) => {
                        setPagination({
                          ...pagination,
                          cities: e.target.value,
                        });
                      }}
                    />
                  </Form.Item>
                </Form>
                <Button
                  className="ml-2"
                  type="primary"
                  onClick={() => {
                    setDataSource([]);
                    setPagination({
                      ...pagination,
                      pageNo: 1,
                      total: 0,
                    });
                    getList({
                      ...pagination,
                      pageNo: 1,
                      total: 0,
                    });
                  }}
                >
                  搜索
                </Button>
              </div>
              <Table
                size="small"
                rowKey={"id"}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  pageSizeOptions: [],
                }}
                onChange={paginationChange}
                virtual={true}
              />
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              推荐人才
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const AdvancedSearchForm = ({ search, pagination, setPagination }) => {
  const [form] = Form.useForm();
  const [address, setAddress] = useState([]);

  useEffect(() => {
    getProvince();
  }, []);

  const onAddressChange = async (val, sel) => {
    // console.log(val, sel);
    sel.map((item, ind) => {
      if (ind == 0) {
        form.setFieldsValue({
          intention_province: item.label,
        });
      }
      if (ind == 1) {
        form.setFieldsValue({
          intention_city: item.label,
        });
      }
      if (ind == 2) {
        form.setFieldsValue({
          intention_area: item.label,
        });
      }
    });
  };

  const loadAddressData = async (selectedOptions) => {
    console.log(selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // load options lazily
    let arr = [];
    if (selectedOptions.length == 1) {
      arr = await getCity(selectedOptions[0].value);
      targetOption.children = [
        ...arr.map((item) => ({
          label: item.name,
          value: item.code,
          code: item.code,
          type: 2,
        })),
      ];
    } else if (selectedOptions.length == 2) {
      return;
    } else {
      return;
    }
    targetOption.loading = false;
    setAddress([...address]);
  };

  const getProvince = async () => {
    const {
      data: { data, code },
    } = await GET_PROVINCE();
    if (code == 200) {
      setAddress([
        ...data.map((item) => ({
          label: item.name,
          value: item.code,
          code: item.code,
          isLeaf: false,
          type: 1,
        })),
      ]);
    }
  };

  const getCity = async (id) => {
    const {
      data: { data, code },
    } = await GET_CITY({
      province_code: id,
    });
    if (code == 200) {
      let addressArr = [...address];
      let ind = addressArr.findIndex((item) => item.value == id);
      if (ind >= 0) {
        addressArr[ind].children = [
          ...data.map((item) => ({
            label: item.name,
            value: item.code,
            code: item.code,
            isLeaf: false,
            type: 2,
          })),
        ];

        setAddress([...addressArr]);
      }

      return data;
    }
  };

  const getArea = async (id, pId) => {
    const {
      data: { data, code },
    } = await GET_AREA({
      city_code: id,
    });
    if (code == 200) {
      if (pId) {
        let addressArr = [...address];
        let pind = addressArr.findIndex((item) => item.value == pId);
        let cind = addressArr[pind].children.findIndex(
          (item) => item.value == id
        );
        if (pind >= 0) {
          addressArr[pind].children[cind].children = [
            ...data.map((item) => ({
              label: item.name,
              value: item.code,
              code: item.code,
              type: 3,
            })),
          ];

          setAddress([...addressArr]);
        }
      }

      return data;
    }
  };

  const onFinish = (values) => {
    if (values?.address?.length) {
      values.work_provinces_code = values.address[0];
      values.work_cities_code = values.address[1];
      values.work_areas_code = values.address[2];
    }
    delete values.address;
    search({
      ...pagination,
      page_size: 5,
      pageNo: 1,
      total: 0,
      ...values,
    });
    setPagination({
      ...pagination,
      page_size: 5,
      pageNo: 1,
      total: 0,
      ...values,
    });
  };
  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="enterprise_name" label="企业名称">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="title" label="岗位名称">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="phone" label="联系人手机号">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="intention_province" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="intention_city" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="intention_area" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="work_provinces_code" label="求职区域">
            <Cascader
              options={address}
              loadData={loadAddressData}
              onChange={onAddressChange}
              changeOnSelect
              placeholder="请选择求职区域"
            />
            {/* <Select options={address} placeholder="请选择求职区域" /> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => {
              form.resetFields();
              search({
                page_size: 5,
                pageNo: 1,
                total: 0,
              });
            }}
          >
            清空
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [recommendVisible, setRecommendVisible] = useState(false);
  const [chooseInfo, setChooseInfo] = useState({});
  const [pagination, setPagination] = useState({
    page_size: 10,
    pageNo: 1,
    total: 0,
  });

  const [dataSource, setDataSource] = useState([]);
  const { pathname } = useLocation();

  const recommend = async () => {
    setRecommendVisible(true);
  };

  const onCreate = async (vals) => {
    console.log(vals);
    vals.user_enterprise_id = chooseInfo.user_enterprise_id;
    vals.job_id = chooseInfo.id;
    vals.interview_date_time = moment(vals.interview_date_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    vals.resume_ids = vals.resume_ids.join(",");
    // return;
    const {
      data: { data, code, message: msg },
    } = await POST_HALL_JOB(vals);

    if (code === 200) {
      message.success("推荐成功");
      getList(pagination);
      setRecommendVisible(false);
      setChooseInfo(false);
    } else {
      message.error(msg);
    }
  };

  const addCollection = async (id) => {
    const {
      data: { data, code, message: msg },
    } = await ADD_HALL_COLLECT({
      ids: id + "",
    });

    if (code == 200) {
      message.success("收藏成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
  };

  const delCollection = async (id) => {
    let {
      data: { data, code, message: msg },
    } = await DEL_HALL_COLLECT({
      ids: id + "",
    });
    if (code == 200) {
      message.success("取消收藏");
      getList(pagination);
    } else {
      message.error(msg);
    }
  };

  const getList = async (paginations) => {
    const {
      data: { data, code },
    } = await GET_HALL_LIST({ ...paginations });
    if (code == 200) {
      setDataSource([...data.data]);
      setPagination({
        ...paginations,
        pageNo: paginations.pageNo || 1,
        current: paginations.pageNo,
        total: data.total,
      });
    }
  };
  const paginationChange = (page) => {
    setPagination({
      ...pagination,
      pageNo: page || 1,
      current: page,
    });
    getList({
      ...pagination,
      pageNo: page || 1,
      current: page,
    });
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div
      className="goods-storage order"
      style={{
        backgroundColor: "transparent",
        padding: "0",
      }}
    >
      <Row gutter={30}>
        <Col span={18}>
          <div
            className="filter-box p-3 mb-3"
            style={{
              background: "#fff",
            }}
          >
            <AdvancedSearchForm
              search={getList}
              pagination={pagination}
              setPagination={setPagination}
            />
          </div>
          <div className="card-list">
            {(dataSource.length &&
              dataSource.map((item, ind) => (
                <Card key={ind} className="mb-3">
                  <CardBody>
                    <div className="d-flex justify-content-between align-item-center">
                      <div
                        className="left"
                        style={{ paddingRight: "90px", width: "60%" }}
                      >
                        <div className="title mb-4">
                          <span className="name mr-3">
                            <Link
                              className="p-0"
                              to={`order/details/${item.id}`}
                              style={{ fontSize: 20 }}
                            >
                              {item.title}
                            </Link>
                          </span>
                          {/* <span className="author fs-12 mr-3">
                         {item.interview_contact} - {item.interview_phone} 
                      </span> */}
                          <span className="time  fs-12">
                            {item.update_time}
                          </span>
                        </div>
                        <div className="intro mb-3">
                          <span>
                            {(item.min_salary_id == 1 &&
                              item.max_salary_id == 1 &&
                              "面议") ||
                              `${item.min_salary} ~ ${item.max_salary}`}
                          </span>
                          <span>
                            {item.work_provinces}/{item.work_cities}/
                            {item.work_areas}/{item.interview_address}
                          </span>
                          <span>{item.education}</span>
                          <span>{item.sex_name}</span>
                          <span>{item.experience}</span>
                          <span>
                            {item.min_age}岁 ~ {item.max_age}岁
                          </span>
                          <span>需 {item.number} 人</span>
                        </div>
                        <div className="tags">
                          {item?.benefits &&
                            item.benefits.split(",").map((item, key) => (
                              <Tag className="mr-2" color="orange" key={key}>
                                {item}
                              </Tag>
                            ))}
                        </div>
                      </div>
                      <div
                        className="right"
                        style={{
                          borderLeft: "1px solid #ddd",
                          paddingRight: "30px",
                          width: "30%",
                          textAlign: "right",
                        }}
                      >
                        <div className="mb-3">{item.user_enterprise}</div>
                        <div style={{ fontSize: 20 }}>
                          <span style={{ color: "red" }}>
                            {item.brokerage} 人才币
                          </span>
                          /人
                        </div>
                        <div className="mt-3">
                          <div className="tip mb-3">{item.interview_time}</div>
                        </div>
                      </div>
                      <div className="buttons" style={{ width: "10%" }}>
                        <div>
                          <div className="mb-3">
                            还可推荐 {item.number_now} 人
                          </div>
                          <div className="mb-3">
                            <Button
                              type="primary"
                              className="mr-3"
                              style={{
                                backgroundColor: "rgb(255,120,3)",
                                borderColor: "rgb(255,120,3)",
                              }}
                              onClick={() => {
                                recommend();
                                setChooseInfo(item);
                              }}
                            >
                              推荐人才
                            </Button>
                          </div>
                          <div>
                            {(item.is_collect && (
                              <Button
                                type="primary"
                                ghost
                                danger
                                onClick={() => {
                                  delCollection(item.id);
                                }}
                              >
                                取消收藏
                              </Button>
                            )) || (
                              <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                  addCollection(item.id);
                                }}
                              >
                                收藏职位
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))) || (
              <div className="p-3" style={{ background: "#fff" }}>
                <Empty></Empty>
              </div>
            )}
          </div>

          <div className="text-left mt-3">
            <Pagination
              pageSize={pagination.page_size}
              current={pagination.pageNo}
              total={pagination.total}
              showSizeChanger={false}
              onChange={(page) => {
                paginationChange(page);
              }}
            />
          </div>
        </Col>
        <Col span={6}>
          <section className="md-section border">
            <div className="btns">
              <Button
                href="/index/order"
                type="primary"
                className="mb-4"
                size="large"
                block
                style={{
                  backgroundColor: "rgb(255,120,3)",
                  borderColor: "rgb(255,120,3)",
                }}
              >
                我是HR 立即推送人才
              </Button>
              <Button
                href="https://www.slzhitui.com/"
                type="primary"
                className="mb-4"
                block
                size="large"
              >
                我是企业 立即发布职位
              </Button>
            </div>
            <header className="md-section-header">常见问题</header>
            <Collapse accordion className="md-section-body">
              <Panel header="1.如何使用悬赏招聘推荐人才赚取佣金？">
                <p>
                  在接单大厅中挑选合适企业然后点击推荐，上传简历，余求职者确定面试时间，完成推荐。
                </p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="2.推荐过后面试/入职后佣金什么时候到账？">
                <p>
                  推荐求职者成功入职且工作满45天，由企业负责招聘的负责人进行点击操作入职满45天按钮即时到账。
                </p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="3.被判为无效推荐有哪些？">
                <p>
                  推荐的求职者面试不通过且中途离职工作未满45天离职即判为无效推荐
                </p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="4.对订单有疑问怎么办？">
                <p>请联系在线客服。</p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="5.推荐的佣金什么时候可以提现？">
                <p>推荐求职者成功入职且工作满45天，即可提现。</p>
              </Panel>
            </Collapse>
          </section>
        </Col>
      </Row>

      {/* 推荐 */}
      {recommendVisible && (
        <CreateShopForm
          visible={recommendVisible}
          onCreate={onCreate}
          onCancel={() => {
            setRecommendVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default List;
