import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
  message,
  Empty,
  InputNumber,
  Select,
  DatePicker,
  TimePicker,
  Tag,
  Pagination,
  Collapse,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import api from "../../api";
import moment from "moment";
import qs from "qs";
import CardHeader from "antd-mobile/lib/card/CardHeader";
import CardBody from "antd-mobile/lib/card/CardBody";
import { GET_RESUME_ALL } from "../../api/resume";
import {
  GET_HALL_LIST,
  ADD_HALL_COLLECT,
  DEL_HALL_COLLECT,
  POST_HALL_JOB,
  GET_POST_JOB,
  GET_HALL_COLLECT,
} from "../../api/hall";

const { Panel } = Collapse;

const CreateShopForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [all, setAll] = useState([]);
  useEffect(async () => {
    form.resetFields();
    if (visible) {
      getAll();
    }
  }, [visible]);

  const getAll = async () => {
    const {
      data: { data, code },
    } = await GET_RESUME_ALL();
    if (code == 200) {
      setAll(data);
    }
  };

  return (
    <Modal
      getContainer={false}
      visible={visible}
      width={850}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>推荐人才</h1>
          </div>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="resume_ids" label="选择简历">
                <Select
                  mode="multiple"
                  placeholder="请选择要推荐的简历"
                  options={all.map((item, key) => ({
                    label: item.name,
                    value: item.resume_id,
                    key: key,
                    item: item,
                  }))}
                  // defaultValue={["a10", "c12"]}
                  style={{
                    width: "100%",
                  }}
                />
              </Form.Item>
              <Form.Item name="interview_date_time" label="面试时间">
                <DatePicker showTime placeholder="请选择面试时间" />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              推荐人才
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [recommendVisible, setRecommendVisible] = useState(false);
  const [chooseInfo, setChooseInfo] = useState({});
  const [pagination, setPagination] = useState({
    page_size: 5,
    pageNo: 1,
    total: 0,
  });

  const [dataSource, setDataSource] = useState([]);

  const recommend = async () => {
    setRecommendVisible(true);
  };

  const onCreate = async (vals) => {
    console.log(vals);
    vals.user_enterprise_id = chooseInfo.user_enterprise_id;
    vals.job_id = chooseInfo.id;
    vals.interview_date_time = moment(vals.interview_date_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    vals.resume_ids = vals.resume_ids.join(",");
    // return;
    const {
      data: { data, code, message: msg },
    } = await POST_HALL_JOB(vals);

    if (code === 200) {
      message.success("推荐成功");
      getList(pagination);
      setRecommendVisible(false);
      setChooseInfo(false);
    } else {
      message.error(msg);
    }
  };

  const addCollection = async (id) => {
    const {
      data: { data, code, message: msg },
    } = await ADD_HALL_COLLECT({
      ids: id + "",
    });

    if (code == 200) {
      message.success("收藏成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
  };

  const delCollection = async (id) => {
    let {
      data: { data, code, message: msg },
    } = await DEL_HALL_COLLECT({
      ids: id + "",
    });
    if (code == 200) {
      message.success("取消收藏");
      getList(pagination);
    } else {
      message.error(msg);
    }
  };

  const getList = async (paginations) => {
    const {
      data: { data, code },
    } = await GET_HALL_COLLECT({ ...paginations });
    if (code == 200) {
      setDataSource([...data.data]);
      setPagination({
        ...pagination,
        pageNo: paginations.pageNo || 1,
        current: paginations.pageNo,
        total: data.total,
      });
    }
  };
  const paginationChange = (page) => {
    setPagination({
      ...pagination,
      pageNo: page || 1,
      current: page,
    });
    getList({
      ...pagination,
      pageNo: page || 1,
      current: page,
    });
    console.log("Page: ", page);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div
      className="goods-storage order"
      style={{
        backgroundColor: "transparent",
        padding: "0",
      }}
    >
      <Row gutter={30}>
        <Col span={18}>
          <div className="card-list">
            {(dataSource.length &&
              dataSource.map((item, ind) => (
                <Card key={ind} className="mb-3">
                  <CardBody>
                    <div className="d-flex justify-content-between align-item-center">
                      <div
                        className="left"
                        style={{ paddingRight: "90px", width: "60%" }}
                      >
                        <div className="title mb-4">
                          <span className="name mr-3">
                            <Link
                              className="p-0"
                              to={`order/details/${item.id}`}
                              style={{ fontSize: 20 }}
                            >
                              {item.title}
                            </Link>
                          </span>
                          {/* <span className="author fs-12 mr-3">
                       {item.interview_contact} - {item.interview_phone} 
                    </span> */}
                          <span className="time  fs-12">
                            {item.update_time}
                          </span>
                        </div>
                        <div className="intro mb-3">
                          <span>
                            {(item.min_salary_id == 1 &&
                              item.max_salary_id == 1 &&
                              "面议") ||
                              `${item.min_salary} ~ ${item.max_salary}`}
                          </span>
                          <span>
                            {item.work_provinces}/{item.work_cities}/
                            {item.work_areas}/{item.interview_address}
                          </span>
                          <span>{item.education}</span>
                          <span>{item.sex_name}</span>
                          <span>{item.experience}</span>
                          <span>需 {item.number} 人</span>
                        </div>
                        <div className="tags">
                          {item?.benefits &&
                            item.benefits.split(",").map((item, key) => (
                              <Tag className="mr-2" color="orange" key={key}>
                                {item}
                              </Tag>
                            ))}
                        </div>
                      </div>
                      <div
                        className="right"
                        style={{
                          borderLeft: "1px solid #ddd",
                          paddingRight: "30px",
                          width: "30%",
                          textAlign: "right",
                        }}
                      >
                        <div className="mb-3">{item.user_enterprise}</div>
                        <div style={{ fontSize: 20 }}>
                          <span style={{ color: "red" }}>
                            {item.brokerage} 人才币
                          </span>
                          /人
                        </div>
                        <div className="mt-3">
                          <div className="tip mb-3">{item.interview_time}</div>
                        </div>
                      </div>
                      <div className="buttons" style={{ width: "10%" }}>
                        <div>
                          <div className="mb-3">
                            还可推荐 {item.number_now} 人
                          </div>
                          <div className="mb-3">
                            <Button
                              type="primary"
                              className="mr-3"
                              style={{
                                backgroundColor: "rgb(255,120,3)",
                                borderColor: "rgb(255,120,3)",
                              }}
                              onClick={() => {
                                recommend();
                                setChooseInfo(item);
                              }}
                            >
                              推荐人才
                            </Button>
                          </div>
                          <div>
                            {(item.is_collect && (
                              <Button
                                type="primary"
                                ghost
                                danger
                                onClick={() => {
                                  delCollection(item.id);
                                }}
                              >
                                取消收藏
                              </Button>
                            )) || (
                              <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                  addCollection(item.id);
                                }}
                              >
                                收藏职位
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))) || (
              <div className="p-3" style={{ background: "#fff" }}>
                <Empty>
                  <Link className="p-0" to={`order`}>
                    <Button type="primary">去收藏职位</Button>
                  </Link>
                </Empty>
              </div>
            )}
          </div>

          <div className="text-left mt-3">
            <Pagination
              pageSize={pagination.page_size}
              current={pagination.pageNo}
              total={pagination.total}
              showSizeChanger={false}
              onChange={(page) => {
                paginationChange(page);
              }}
            />
          </div>
        </Col>
        <Col span={6}>
          <section className="md-section border">
            <div className="btns">
              <Button
                href="/index/order"
                type="primary"
                className="mb-4"
                size="large"
                block
                style={{
                  backgroundColor: "rgb(255,120,3)",
                  borderColor: "rgb(255,120,3)",
                }}
              >
                我是HR 立即推送人才
              </Button>
              <Button
                href="https://www.slzhitui.com/"
                type="primary"
                className="mb-4"
                block
                size="large"
              >
                我是企业 立即发布职位
              </Button>
            </div>
            <header className="md-section-header">常见问题</header>
            <Collapse accordion className="md-section-body">
              <Panel header="1.如何使用悬赏招聘推荐人才赚取佣金？">
                <p>
                  在接单大厅中挑选合适企业然后点击推荐，上传简历，余求职者确定面试时间，完成推荐。
                </p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="2.推荐过后面试/入职后佣金什么时候到账？">
                <p>
                  推荐求职者成功入职且工作满45天，由企业负责招聘的负责人进行点击操作入职满45天按钮即时到账。
                </p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="3.被判为无效推荐有哪些？">
                <p>
                  推荐的求职者面试不通过且中途离职工作未满45天离职即判为无效推荐
                </p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="4.对订单有疑问怎么办？">
                <p>请联系在线客服。</p>
              </Panel>
            </Collapse>
            <Collapse accordion className="md-section-body">
              <Panel header="5.推荐的佣金什么时候可以提现？">
                <p>推荐求职者成功入职且工作满45天，即可提现。</p>
              </Panel>
            </Collapse>
          </section>
        </Col>
      </Row>

      {/* 推荐 */}
      {recommendVisible && (
        <CreateShopForm
          visible={recommendVisible}
          onCreate={onCreate}
          onCancel={() => {
            setRecommendVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default List;
