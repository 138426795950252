import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";

// 登录
export const LOGIN = async (params) => {
  return await instance
    .post(`${base}/hr/login/login`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};

// 获取注册短信验证码
export const GET_REG_CODE = async (params) => {
  return await instance
    .post(`${base}/hr/login/getRegCode`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};

// 注册
export const REGISTER = async (params) => {
  return await instance
    .post(`${base}/hr/login/register`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};

// 获取忘记密码短信验证码
export const GET_FORGET_CODE = async (params) => {
  return await instance
    .post(`${base}/hr/login/getForgetCode`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};

// 忘记密码
export const FORGET = async (params) => {
  return await instance
    .post(`${base}/hr/login/forget`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 获取用户信息
export const GET_USER_INFO = async (params) => {
  return await instance
    .get(`${base}/hr/user/info`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};

// 申请提现
export const USER_EXCHANGE_BALANCE = async (params) => {
  return await instance
    .post(`${base}/hr/user/exchange`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 收支明细
export const USER_LOG = async (params) => {
  return await instance
    .post(`${base}/hr/user/log`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
