import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Radio,
  Select,
  Button,
  Modal,
  Cascader,
  message,
  Upload,
  Slider,
  InputNumber,
  Tag,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import api from "../../api";
import moment from "moment";
import qs from "qs";

import { GET_POST_JOB, DELETE_RESUME_JOB } from "../../api/hall";
import { GET_PROVINCE, GET_CITY, GET_AREA, GET_TABLE } from "../../api/base";

const AdvancedSearchForm = ({ search, pagination, setPagination }) => {
  const [form] = Form.useForm();
  const [address, setAddress] = useState([]);

  useEffect(() => {
    getProvince();
  }, []);

  const onAddressChange = async (val, sel) => {
    // console.log(val, sel);
    sel.map((item, ind) => {
      if (ind == 0) {
        form.setFieldsValue({
          intention_province: item.label,
        });
      }
      if (ind == 1) {
        form.setFieldsValue({
          intention_city: item.label,
        });
      }
      if (ind == 2) {
        form.setFieldsValue({
          intention_area: item.label,
        });
      }
    });
  };

  const loadAddressData = async (selectedOptions) => {
    console.log(selectedOptions);
    const targetOption = selectedOptions[selectedOptions.length - 1];
    targetOption.loading = true;
    // load options lazily
    let arr = [];
    if (selectedOptions.length == 1) {
      arr = await getCity(selectedOptions[0].value);
      targetOption.children = [
        ...arr.map((item) => ({
          label: item.name,
          value: item.code,
          code: item.code,
          type: 2,
        })),
      ];
    } else if (selectedOptions.length == 2) {
      return;
    } else {
      return;
    }
    targetOption.loading = false;
    setAddress([...address]);
  };

  const getProvince = async () => {
    const {
      data: { data, code },
    } = await GET_PROVINCE();
    if (code == 200) {
      setAddress([
        ...data.map((item) => ({
          label: item.name,
          value: item.code,
          code: item.code,
          isLeaf: false,
          type: 1,
        })),
      ]);
    }
  };

  const getCity = async (id) => {
    const {
      data: { data, code },
    } = await GET_CITY({
      province_code: id,
    });
    if (code == 200) {
      let addressArr = [...address];
      let ind = addressArr.findIndex((item) => item.value == id);
      if (ind >= 0) {
        addressArr[ind].children = [
          ...data.map((item) => ({
            label: item.name,
            value: item.code,
            code: item.code,
            isLeaf: false,
            type: 2,
          })),
        ];

        setAddress([...addressArr]);
      }

      return data;
    }
  };

  const onFinish = (values) => {
    if (values?.address?.length) {
      values.work_provinces_code = values.address[0];
      values.work_cities_code = values.address[1];
      values.work_areas_code = values.address[2];
    }
    delete values.address;
    search({
      ...pagination,
      page_size: 5,
      pageNo: 1,
      total: 0,
      ...values,
    });
    setPagination({
      ...pagination,
      page_size: 5,
      pageNo: 1,
      total: 0,
      ...values,
    });
  };
  return (
    <Form
      form={form}
      name="advanced_search"
      className="ant-advanced-search-form"
      onFinish={onFinish}
    >
      <Row gutter={24}>
        <Col span={8}>
          <Form.Item name="sex" label="性别">
            <Select
              options={[
                {
                  label: "不限",
                  value: undefined,
                },
                {
                  label: "男",
                  value: "男",
                },
                {
                  label: "女",
                  value: "女",
                },
              ]}
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="title" label="职位">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="enterpriseName" label="推送企业">
            <Input placeholder="请输入搜索关键词" />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item name="intention_province" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="intention_city" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="intention_area" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="work_provinces_code" label="区域">
            <Cascader
              options={address}
              loadData={loadAddressData}
              onChange={onAddressChange}
              changeOnSelect
              placeholder="请选择求职区域"
            />
            {/* <Select options={address} placeholder="请选择求职区域" /> */}
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col
          span={24}
          style={{
            textAlign: "right",
          }}
        >
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => {
              form.resetFields();
              search({
                page_size: 5,
                pageNo: 1,
                total: 0,
              });
            }}
          >
            清空
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

const List = () => {
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });

  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: "姓名",
      dataIndex: ["resume", "name"],
      key: ["resume", "name"],
    },
    {
      title: "性别",
      dataIndex: ["resume", "sex"],
      key: ["resume", "sex"],
    },
    {
      title: "联系方式",
      dataIndex: ["resume", "mobile"],
      key: ["resume", "mobile"],
    },
    {
      title: "年龄",
      dataIndex: ["resume", "age"],
      key: ["resume", "age"],
    },
    {
      title: "推荐职位",
      dataIndex: "job",
      key: "job",
      render: (text, data) => data.job.title,
    },
    {
      title: "岗位需求学历",
      dataIndex: ["job", "education"],
      key: ["job", "education"],
    },
    {
      title: "岗位需求工作经验",
      dataIndex: ["job", "experience"],
      key: ["job", "education"],
    },
    {
      title: "岗位联系人",
      dataIndex: "resume_skill",
      key: "resume_skill",
      render: (text, data) => (
        <div>
          <div className="mb-1">{data.user_enterprise}</div>
          <div className="mb-1">{data.job.interview_contact}</div>
          <div>{data.job.interview_phone}</div>
        </div>
      ),
    },
    {
      title: "岗位地址",
      dataIndex: "city",
      key: "city",
      render: (text, data) => (
        <div>
          <div className="mb-1">
            {data.job.work_provinces}/{data.job.work_cities}/
            {data.job.work_areas}
          </div>
          <div>{data.job.interview_address}</div>
        </div>
      ),
    },
    {
      title: "岗位期望薪资",
      dataIndex: "min_salary",
      key: "min_salary",
      align: "center",
      render: (text, data) =>
        (data.job.min_salary == 1 && data.job.max_salary == 1 && "面议") || (
          <div>
            {data.job.min_salary || "暂无"} ~{data.job.max_salary || "暂无"}
          </div>
        ),
    },
    {
      title: "推荐时间",
      dataIndex: "create_time",
      key: "create_time",
    },
    {
      title: "面试时间",
      dataIndex: "interview_time",
      key: "interview_time",
    },
    {
      title: "返还人才币",
      dataIndex: "job",
      key: "job",
      render: (text, data) => <span>{data.job.brokerage}</span>,
    },
    {
      title: "状态",
      dataIndex: "status_name",
      key: "status_name",
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要取消该推荐吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "取消推荐后将无法获得人才币，确认吗?",
                  onOk() {
                    onRemove({
                      id: data.id,
                    });
                  },
                  onCancel() {},
                });
              }}
            >
              取消推荐
            </Button>
          </div>
        );
      },
    },
  ];

  const onRemove = async (values) => {
    let {
      data: { data, code, message: msg },
    } = await DELETE_RESUME_JOB({
      ids: values.id + "",
    });
    if (code == 200) {
      message.success("取消推荐成功");
      getList();
    } else {
      message.error(msg);
    }
  };

  const getList = async (paginations) => {
    const {
      data: {
        code,
        data: { data, pageNo, pageSize, pageTotal, total },
      },
    } = await GET_POST_JOB({ ...paginations });
    if (code == 200) {
      setDataSource(data);
      setPagination({
        ...pagination,
        total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
    });
    console.log("Page: ", pages);
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div
        className="filter-box p-3 mb-3"
        style={{
          background: "#fff",
        }}
      >
        <AdvancedSearchForm
          search={getList}
          pagination={pagination}
          setPagination={setPagination}
        />
      </div>

      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />
    </div>
  );
};

export default List;
