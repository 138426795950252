import React from "react";
import { Link } from "react-router-dom";
import {
  Form,
  Input,
  Button,
  Col,
  Row,
  Spin,
  Modal,
  Checkbox,
  message,
} from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
  PhoneOutlined,
  MailOutlined,
} from "@ant-design/icons";
import "./login.less";
import cookie from "react-cookies";
import { GET_FORGET_CODE, FORGET } from "../../api/login";
import uitls from "../../assets/js/public";
export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
      sendDisabled: false,
      time: 120,
      formVals: {},
    };
  }

  componentDidMount = () => {
    if (!!cookie.load("accessToken")) {
      this.props.history.push("/index/overview");
    }
  };

  count = () => {
    let { time } = this.state;
    let siv = setInterval(() => {
      this.setState({ time: time-- }, () => {
        if (time <= -1) {
          clearInterval(siv); //倒计时( setInterval() 函数会每秒执行一次函数)，用 clearInterval() 来停止执行:
          this.setState({ sendDisabled: false, time: 120 });
        }
      });
    }, 1000);
  };

  register = async (values) => {
    this.setState({ loading: true });
    if (values.pwd_secret != values.userPwdVeryf) {
      message.error("密码与确认密码不一致");
      return false;
    }
    // values = Object.assign(values);
    if (!localStorage.getItem("codeid")) {
      message.error("请先获取短信验证码");
      return false;
    }
    delete values.userPwdVeryf;
    let {
      data: { data, code, message: msg },
    } = await FORGET(values);
    if (code === 200) {
      message.success("找回成功,请进行登录操作");
      localStorage.removeItem("codeid");
      this.props.history.push("/login");
      return;
    }
    message.error(msg);
    this.setState({ loading: false });
  };

  render() {
    const { formVals } = this.state;
    return (
      <div className="login-cont">
        <div className="copyright">
          <p>
            Copyright © 2022 速猎直推
            <a href="https://beian.miit.gov.cn/" target="_blank">
              沪ICP备2021024285号-1
            </a>
          </p>
        </div>
        <div className="login-sign">
          <div className="title">
            <h1>找回</h1>
            <p>
              没有账号？
              <Link to="/register">注册</Link>
            </p>
          </div>
          <Form
            className="login-form"
            onFinish={this.register}
            name="forget"
            initialValues={{ remember: true }}
            onValuesChange={(vals) => {
              let formVals = { ...this.state.formVals, ...vals };
              this.setState({ formVals: formVals });
            }}
          >
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: "请输入手机号" }]}
            >
              <Input
                size="large"
                allowClear
                maxLength={11}
                prefix={
                  <PhoneOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                placeholder="请输入手机号"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password
                size="large"
                allowClear
                prefix={
                  <LockOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item
              name="confirm_password"
              rules={[{ required: true, message: "请再次输入密码" }]}
            >
              <Input.Password
                size="large"
                allowClear
                prefix={
                  <LockOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="请再次输入密码"
              />
            </Form.Item>

            <Form.Item>
              <Row gutter={8}>
                <Col span={16}>
                  <Form.Item
                    name="smscode"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: "请输入短信码",
                      },
                    ]}
                  >
                    <Input
                      size="large"
                      allowClear
                      prefix={
                        <MailOutlined
                          style={{
                            color: "rgba(0,0,0,.25)",
                            fontSize: "18px",
                          }}
                        />
                      }
                      placeholder="请输入短信码"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Button
                    size="large"
                    block
                    disabled={this.state.sendDisabled}
                    onClick={async () => {
                      if (!uitls.phone(formVals.mobile)) {
                        message.error("请输入正确的手机号码");
                        return;
                      }

                      if (this.state.time > 0) {
                        this.setState({ sendDisabled: true });
                        let {
                          data: { data, code, message: msg },
                        } = await GET_FORGET_CODE({
                          mobile: formVals.mobile,
                        });
                        if (code == 200) {
                          message.success("短信发送成功，请注意查收");
                          this.count();
                          localStorage.setItem("codeid", 1);
                        } else {
                          message.success(msg);
                          this.setState({ sendDisabled: false, time: 120 });
                        }
                      }
                    }}
                  >
                    {this.state.sendDisabled
                      ? this.state.time + "秒"
                      : "获取短信码"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                style={{ fontSize: "14px", borderRadius: "5px" }}
                block
              >
                立即注册
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
