import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";
// 获取省份
export const GET_PROVINCE = async (params) => {
  return await instance
    .post(`${base}/base/base/province`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 获取城市
export const GET_CITY = async (params) => {
  return await instance
    .post(`${base}/base/base/city`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 获取区县
export const GET_AREA = async (params) => {
  return await instance
    .post(`${base}/base/base/area`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 获取基础列表
export const GET_TABLE = async (params) => {
  return await instance
    .post(`${base}/base/base/list`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
