export default  {
    "-1": "系统繁忙",
    "0": "请求成功",
    "1001":	"超级管理员只能有一个",
    "1002":	"用户已存在",
    "1003":	"两次密码不一致",
    "1004":	"手机号错误",
    "1005":	"邮箱格式错误",
    "1006":	"参数错误",
    "1007":	"验证码错误",
    "1008":	"获取验证码次数已达上限",
    "1009":	"公众号已存在",
    "1010":	"公众号菜单已存在",
    "1011":	"资源已存在",
    "1012":	"与当前模块对应的资源不存在",
    "1013":	"临时二维码未到期不能删除",
    "1014":	"正在同步，请勿重复提交请求",
    "2001":	"用户名密码错误",
    "2002":	"用户不存在",
    "2003":	"微信接口异常",
    "2004":	"创蓝短息接口异常",
    "3001":	"未授权",
    "3002":	"无操作权限",
    "3003":	"不能删除自己的账号",
}
