import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";
// 全部简历列表
export const GET_RESUME_ALL = async (params) => {
  return await instance
    .post(`${base}/hr/resume/all`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 简历列表
export const GET_RESUME_LIST = async (params) => {
  return await instance
    .post(`${base}/hr/resume/list`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 新增简历
export const ADD_RESUME = async (params) => {
  return await instance
    .post(`${base}/hr/resume/add`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 修改简历
export const UPDATE_RESUME = async (params) => {
  return await instance
    .post(`${base}/hr/resume/update`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 删除简历
export const DELETE_RESUME = async (params) => {
  return await instance
    .post(`${base}/hr/resume/delete`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 导入简历
export const IMPORT_RESUME = async (params) => {
  return await instance
    .post(`${base}/hr/resume/import`, params, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
