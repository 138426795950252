import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  Input,
  Radio,
  Select,
  Button,
  Modal,
  Cascader,
  message,
  Upload,
  Slider,
  InputNumber,
  Tag,
  Space,
  DatePicker,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import api from "../../api";
import moment from "moment";
import qs from "qs";
import { GET_PROVINCE, GET_CITY, GET_AREA, GET_TABLE } from "../../api/base";
import {
  GET_RESUME_LIST,
  ADD_RESUME,
  UPDATE_RESUME,
  DELETE_RESUME,
  IMPORT_RESUME,
} from "../../api/resume";

const CreateShopForm = ({ visible, info = {}, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [education, setEducation] = useState([]);
  const [salary, setSalary] = useState([]);
  const [experience, setExperience] = useState([]);
  const [occupation, setOccupation] = useState([]);
  useEffect(async () => {
    form.resetFields();
    if (visible) {
      getTable("education");
      getTable("experience");
      getTable("salary");
      getTable("occupation");
      if (info) {
        console.log(info);
        info.experienceList.map((item, ind) => {
          info.experienceList[ind].entry_time = moment(item.entry_time);
          info.experienceList[ind].depart_time = moment(item.depart_time);
        });
        info.educationList.map((item, ind) => {
          info.educationList[ind].start_date = moment(item.start_date);
          info.educationList[ind].end_date = moment(item.end_date);
        });
        info.projectList.map((item, ind) => {
          info.projectList[ind].start_date = moment(item.start_date);
          info.projectList[ind].end_date = moment(item.end_date);
        });
        await form.setFieldsValue({
          ...info,
        });
        setFormVals(info);
      }
    }
  }, [visible]);

  const getTable = async (type) => {
    const {
      data: { data, code },
    } = await GET_TABLE({
      table: type,
    });
    if (code == 200) {
      if (type == "education") {
        setEducation([
          ...data.map((item) => ({
            label: item.title,
            value: item.title,
          })),
        ]);
      }
      if (type == "experience") {
        setExperience([
          ...data.map((item) => ({
            label: item.title,
            value: item.title,
          })),
        ]);
      }
      if (type == "salary") {
        setSalary([
          ...data.map((item) => ({
            label: item.title,
            value: item.title,
          })),
        ]);
      }
      if (type == "occupation") {
        setOccupation([
          ...data.map((item) => ({
            label: item.title,
            value: item.title,
          })),
        ]);
      }
    }
  };

  return (
    <Modal
      getContainer={false}
      visible={visible}
      width={850}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {}}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>
              {info.id ? "修改" : "创建"}简历{" "}
              <small className="fs-14">
                (请保证输入的信息真实有效，平台将进行核实，请勿发布虚假不实信息)
              </small>
            </h1>
          </div>
          <Form
            form={form}
            layout="vertical"
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              setFormVals(allVals);
              console.log(allVals);
            }}
          >
            <div>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <div className="page-header">
                <div className="title mb-3">基础信息</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="name" label="真实姓名">
                    <Input placeholder="请输入" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="age" label="年龄">
                    <InputNumber
                      placeholder="请输入"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="mobile" label="联系方式">
                    <Input maxLength={11} placeholder="请输入" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="experience" label="工作经验">
                    <Select options={experience} placeholder="请选择" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="sex" label="性别">
                    <Select
                      optionType="button"
                      options={[
                        {
                          label: "男",
                          value: "男",
                        },
                        {
                          label: "女",
                          value: "女",
                        },
                      ]}
                      placeholder="请选择"
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="education" label="学历">
                    <Select options={education} placeholder="请选择" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="state" label="求职状态">
                    <Select
                      options={[
                        {
                          label: "在职-月内到岗",
                          value: "在职-月内到岗",
                        },
                        {
                          label: "离职-随时到岗",
                          value: "离职-随时到岗",
                        },
                        {
                          label: "在职-考虑机会",
                          value: "在职-考虑机会",
                        },
                        {
                          label: "在职-暂不考虑",
                          value: "在职-暂不考虑",
                        },
                      ]}
                      placeholder="请选择"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">求职意向</div>
              </div>
              <Row gutter={30}>
                <Col span={6}>
                  <Form.Item name="occupation" label="意向职位">
                    <Select options={occupation} placeholder="请选择" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item name="cities" label="意向城市">
                    <Input placeholder="请填写" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="期望薪资">
                    <Row gutter={30}>
                      <Col span={11}>
                        <Form.Item name="min_salary" className="m-0">
                          <Select options={salary} placeholder="请选择" />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <div
                          className="text-center"
                          style={{ lineHeight: "30px" }}
                        >
                          ~
                        </div>
                      </Col>
                      <Col span={11}>
                        <Form.Item name="max_salary" className="m-0">
                          <Select options={salary} placeholder="请选择" />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>

              <div className="page-header">
                <div className="title mb-3">个人优势</div>
              </div>
              <Form.Item name="advantage">
                <Input.TextArea placeholder="请填写" />
              </Form.Item>

              <div className="page-header">
                <div className="title mb-3">社交主页</div>
              </div>
              <Form.Item name="page">
                <Input placeholder="选填" />
              </Form.Item>
              <div className="page-header">
                <div className="title mb-3">工作经历</div>
              </div>
              <Form.Item name="expDelIds" hidden>
                <Input />
              </Form.Item>
              <Form.List name="experienceList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "block",
                          background: "rgb(248,248,248)",
                          padding: 15,
                          marginBottom: 20,
                          position: "relative",
                        }}
                        align="baseline"
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            if (formVals.experienceList[name]?.id) {
                              formVals.expDelIds =
                                (formVals.expDelIds &&
                                  formVals.expDelIds?.split(",")) ||
                                [];
                              formVals.expDelIds.push(
                                formVals.experienceList[name]?.id
                              );
                              form.setFieldsValue({
                                expDelIds: formVals.expDelIds.join(","),
                              });
                              formVals.expDelIds = formVals.expDelIds.join(",");
                            }
                            remove(name);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        />
                        <Form.Item {...restField} name={[name, "id"]} hidden>
                          <Input />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "corporate_name"]}
                              label="公司名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "corporate_industry"]}
                              label="公司行业"
                            >
                              <Select
                                options={occupation}
                                placeholder="请选择"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item label="在职时间">
                              <Row gutter={30}>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "entry_time"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <div
                                    className="text-center"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    ~
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "depart_time"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "position"]}
                              label="职位名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          {...restField}
                          name={[name, "work_content"]}
                          label="工作内容"
                        >
                          <Input.TextArea placeholder="请填写" />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "pay"]}
                              label="月薪(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "department"]}
                              label="所属部门(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "report"]}
                              label="汇报对象(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={6}>
                            <Form.Item
                              {...restField}
                              name={[name, "subordinates"]}
                              label="下属人数(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Form.Item
                          {...restField}
                          name={[name, "work_location"]}
                          label="工作地点(选填)"
                        >
                          <Input placeholder="请填写" />
                        </Form.Item>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        添加工作经历
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <div className="page-header">
                <div className="title mb-3">教育经历</div>
              </div>
              <Form.Item name="eduDelIds" hidden>
                <Input />
              </Form.Item>
              <Form.List name="educationList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "block",
                          background: "rgb(248,248,248)",
                          padding: 15,
                          marginBottom: 20,
                          position: "relative",
                        }}
                        align="baseline"
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            if (formVals.educationList[name]?.id) {
                              formVals.eduDelIds =
                                (formVals.eduDelIds &&
                                  formVals.eduDelIds?.split(",")) ||
                                [];
                              formVals.eduDelIds.push(
                                formVals.educationList[name]?.id
                              );
                              form.setFieldsValue({
                                eduDelIds: formVals.eduDelIds.join(","),
                              });
                              formVals.eduDelIds = formVals.eduDelIds.join(",");
                            }
                            remove(name);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        />

                        <Form.Item {...restField} name={[name, "id"]} hidden>
                          <Input />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "school_name"]}
                              label="学校名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "education"]}
                              label="学历"
                            >
                              <Select
                                options={education}
                                placeholder="请选择"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "professional_name"]}
                              label="专业名称"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="在校时间">
                              <Row gutter={30}>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "start_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <div
                                    className="text-center"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    ~
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "end_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        添加教育经历
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <div className="page-header">
                <div className="title mb-3">项目经历</div>
              </div>
              <Form.Item name="proDelIds" hidden>
                <Input />
              </Form.Item>
              <Form.List name="projectList">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "block",
                          background: "rgb(248,248,248)",
                          padding: 15,
                          marginBottom: 20,
                          position: "relative",
                        }}
                        align="baseline"
                      >
                        <MinusCircleOutlined
                          onClick={() => {
                            if (formVals.projectList[name]?.id) {
                              formVals.proDelIds =
                                (formVals.proDelIds &&
                                  formVals.proDelIds?.split(",")) ||
                                [];
                              formVals.proDelIds.push(
                                formVals.projectList[name]?.id
                              );
                              form.setFieldsValue({
                                proDelIds: formVals.proDelIds.join(","),
                              });
                              formVals.proDelIds = formVals.proDelIds.join(",");
                            }
                            remove(name);
                          }}
                          style={{
                            position: "absolute",
                            top: -5,
                            right: -5,
                          }}
                        />

                        <Form.Item {...restField} name={[name, "id"]} hidden>
                          <Input />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "product_name"]}
                          label="项目名称"
                        >
                          <Input placeholder="请填写" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "product_intro"]}
                          label="项目描述"
                        >
                          <Input.TextArea placeholder="请填写" />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "achieve"]}
                          label="项目成就"
                        >
                          <Input.TextArea placeholder="请填写" />
                        </Form.Item>
                        <Row gutter={30}>
                          <Col span={12}>
                            <Form.Item label="项目时间">
                              <Row gutter={30}>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "start_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                                <Col span={2}>
                                  <div
                                    className="text-center"
                                    style={{ lineHeight: "30px" }}
                                  >
                                    ~
                                  </div>
                                </Col>
                                <Col span={11}>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "end_date"]}
                                    className="m-0"
                                  >
                                    <DatePicker placeholder="请选择" />
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item
                              {...restField}
                              name={[name, "link"]}
                              label="项目链接(选填)"
                            >
                              <Input placeholder="请填写" />
                            </Form.Item>
                          </Col>
                        </Row>
                      </Space>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                      >
                        添加项目经历
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item name="education" hidden>
                <Input />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldValue());
                console.log(form.getFieldValue());
              }}
            >
              {info.id ? "修改" : "创建"}简历
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const UploadFiles = ({ visible, setVisible, sub, cancel }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [backgroundFileList, setBackgroundFileList] = useState([]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      width={850}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        sub(form.getFieldsValue());
      }}
      onCancel={cancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>导入简历</h1>
          </div>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {}}
          >
            <div>
              <Form.Item name="file" label="导入简历">
                <Upload
                  name="background"
                  listType="picture-card"
                  maxCount={1}
                  fileList={backgroundFileList}
                  beforeUpload={(file, beforeUploadFileList) => {
                    let thumbUrl =
                      require("../../assets/image/excel.png").default;
                    setBackgroundFileList([{ ...file, thumbUrl: thumbUrl }]);
                    return false;
                  }}
                >
                  <div>
                    {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    <div
                      style={{
                        marginTop: 8,
                      }}
                    >
                      点击上传简历模板
                    </div>
                  </div>
                </Upload>
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                sub(form.getFieldsValue());
              }}
            >
              确认导入
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState({});

  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "性别",
      dataIndex: "sex",
      key: "sex",
    },
    {
      title: "联系方式",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "年龄",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "意向职业",
      dataIndex: "occupation",
      key: "occupation",
    },
    {
      title: "意向城市",
      dataIndex: "cities",
      key: "cities",
    },
    {
      title: "学历",
      dataIndex: "education",
      key: "education",
    },
    {
      title: "求职状态",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "工作经验",
      dataIndex: "experience",
      key: "experience",
      align: "center",
    },
    {
      title: "期望薪资",
      dataIndex: "experience",
      key: "experience",
      align: "center",
      render: (text, data) =>
        (data.min_salary_id == 1 && data.max_salary_id == 1 && "面议") || (
          <div>
            {data.min_salary || "暂无"} ~ {data.max_salary || "暂无"}
          </div>
        ),
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "最后修改时间",
      dataIndex: "update_time",
      key: "update_time",
      render: (text, data) => moment(text).format("YYYY/MM/DD HH:mm:ss"),
    },
    {
      title: "操作",
      key: "action",
      render: (text, data) => {
        return (
          <div>
            <Button
              type="link"
              size="small"
              onClick={() => {
                setVisibleCreate(true);
                setVisibleInfo({
                  ...data,
                });
              }}
            >
              编辑信息
            </Button>
            <span>|</span>
            <Button
              type="link"
              danger
              size="small"
              onClick={() => {
                Modal.confirm({
                  title: "确定要删除该简历吗?",
                  icon: <ExclamationCircleOutlined />,
                  content: "删除后将无法寻回，确认吗?",
                  onOk() {
                    onRemove(data);
                  },
                  onCancel() {},
                });
              }}
            >
              删除
            </Button>
          </div>
        );
      },
    },
  ];

  const onCreate = async (values) => {
    let res;
    console.log(values);
    values.experienceList =
      (values.experienceList &&
        values.experienceList.length &&
        values.experienceList.map((item) => ({
          ...item,
          depart_time: moment(item.depart_time).format("YYYY-MM-DD"),
          entry_time: moment(item.entry_time).format("YYYY-MM-DD"),
        }))) ||
      [];
    values.educationList =
      (values.educationList &&
        values.educationList.length &&
        values.educationList.map((item) => ({
          ...item,
          start_date: moment(item.start_date).format("YYYY-MM-DD"),
          end_date: moment(item.end_date).format("YYYY-MM-DD"),
        }))) ||
      [];
    values.projectList =
      (values.projectList &&
        values.projectList.length &&
        values.projectList.map((item) => ({
          ...item,
          start_date: moment(item.start_date).format("YYYY-MM-DD"),
          end_date: moment(item.end_date).format("YYYY-MM-DD"),
        }))) ||
      [];
    // return;
    // if (values.address.length) {
    //   values.intention_province_code = values.address[0];
    //   values.intention_city_code = values.address[1];
    //   values.intention_area_code = values.address[2];
    // }
    // delete values.address;
    if (visibleInfo.id) {
      res = await UPDATE_RESUME(values);
    } else {
      res = await ADD_RESUME(values);
    }
    if (res.data.code == 200) {
      message.success(res.data.data.message);
    } else {
      message.error(res.data.data.message);
    }
    getList(pagination);
    setVisibleCreate(false);
  };
  const onRemove = async (values) => {
    let {
      data: { data, code, message: msg },
    } = await DELETE_RESUME({
      id: values.id,
    });
    if (code == 200) {
      message.success("删除成功");
      getList(pagination);
    } else {
      message.error(msg);
    }
    setVisibleCreate(false);
  };

  const getList = async (paginations) => {
    const {
      data: {
        code,
        data: { data, pageNo, pageSize, pageTotal, total },
      },
    } = await GET_RESUME_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data);
      setPagination({
        ...paginations,
        total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
    });
    console.log("Page: ", pages);
  };

  const onUploadSub = async (vals) => {
    let fileInfo = vals.file;
    vals.file = fileInfo.file;
    const fd = new FormData();
    fd.append("file", fileInfo.file);
    const {
      data: { data, code, message: msg },
    } = await IMPORT_RESUME(fd);

    if (code == 200) {
      message.success("导入成功");
      setVisibleUpload(false);
      getList();
    } else {
      message.error(msg);
    }
  };

  useEffect(() => {
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <div className="btn-box">
        <Button
          type="primary"
          className="mr-3"
          onClick={() => {
            setVisibleCreate(true);
          }}
        >
          新建简历
        </Button>
        <Button
          type="primary"
          ghost
          className="mr-3"
          onClick={() => {
            setVisibleUpload(true);
          }}
        >
          导入简历
        </Button>
        <Button
          type="link"
          ghost
          className="mr-3"
          href="http://admin.slzhitui.com/%E7%AE%80%E5%8E%86%E6%A8%A1%E6%9D%BF.xlsx"
          target="_blank"
        >
          获取导入简历模板
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: pagination.pageSize,
          total: pagination.total,
        }}
        onChange={paginationChange}
      />

      {/* 创建 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          info={visibleInfo}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
            setVisibleInfo({});
          }}
        />
      )}
      {/* 上传 */}
      {visibleUpload && (
        <UploadFiles
          visible={visibleUpload}
          setVisible={setVisibleUpload}
          sub={onUploadSub}
          cancel={() => {
            setVisibleUpload(false);
          }}
        />
      )}
    </div>
  );
};

export default List;
