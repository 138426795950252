import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";
// 接单大厅 列表
export const GET_HALL_LIST = async (params) => {
  return await instance
    .post(`${base}/hr/hall/list`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 职位详情
export const GET_HALL_DETAILS = async (params) => {
  return await instance
    .post(`${base}/hr/hall/detail`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 收藏职位列表
export const GET_HALL_COLLECT = async (params) => {
  return await instance
    .post(`${base}/hr/hall/collect`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 收藏职位
export const ADD_HALL_COLLECT = async (params) => {
  return await instance
    .post(`${base}/hr/hall/collectadd`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 取消收藏职位
export const DEL_HALL_COLLECT = async (params) => {
  return await instance
    .post(`${base}/hr/hall/collectdel`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};

// 推荐人才
export const POST_HALL_JOB = async (params) => {
  return await instance
    .post(`${base}/hr/hall/jobPost`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 查询推荐列表
export const GET_POST_JOB = async (params) => {
  return await instance
    .post(`${base}/hr/resume/resumeList`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
// 取消推荐
export const DELETE_RESUME_JOB = async (params) => {
  return await instance
    .post(`${base}/hr/resume/resumeDel`, params)
    .then((res) => {
      return res;
    })
    .catch((error) => {
      message.error("服务器出错");
    });
};
