import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";
export default {
  //获取用户信息
  async userinfo(params) {
    return await instance
      .post(`${base}/client/user/info`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
};
