import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  Row,
  Col,
  Form,
  Button,
  Card,
  Modal,
  Select,
  Tag,
  DatePicker,
  Spin,
  Input,
  Table,
  message,
} from "antd";
import "./list.less";
import cookie from "react-cookies";
import { ExclamationCircleOutlined, RollbackOutlined } from "@ant-design/icons";
import moment from "moment";
import qs from "qs";
import CardBody from "antd-mobile/lib/card/CardBody";

import { GET_RESUME_ALL, GET_RESUME_LIST } from "../../api/resume";
import {
  GET_HALL_DETAILS,
  ADD_HALL_COLLECT,
  DEL_HALL_COLLECT,
  POST_HALL_JOB,
} from "../../api/hall";

const CreateShopForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  const [all, setAll] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = React.useState([]);
  const [filterForm] = Form.useForm();
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
    name: "",
  });

  useEffect(async () => {
    form.resetFields();
    if (visible) {
      // getAll();
      getList(pagination);
    }
  }, [visible]);

  const getAll = async () => {
    const {
      data: { data, code },
    } = await GET_RESUME_ALL();
    if (code == 200) {
      setAll(data);
    }
  };

  const getList = async (paginations) => {
    const {
      data: {
        code,
        data: { data, pageNo, pageSize, pageTotal, total },
      },
    } = await GET_RESUME_LIST({ ...paginations });
    if (code == 200) {
      setDataSource(data);
      setPagination({
        ...paginations,
        total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
    });
    console.log("Page: ", pages);
  };

  const columns = [
    {
      title: "姓名",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "性别",
      dataIndex: "sex",
      key: "sex",
    },
    {
      title: "联系方式",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "年龄",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "意向职业",
      dataIndex: "occupation",
      key: "occupation",
    },
    {
      title: "意向城市",
      dataIndex: "cities",
      key: "cities",
    },
    {
      title: "学历",
      dataIndex: "education",
      key: "education",
    },
    {
      title: "求职状态",
      dataIndex: "state",
      key: "state",
    },
    {
      title: "工作经验",
      dataIndex: "experience",
      key: "experience",
      align: "center",
    },
    {
      title: "期望薪资",
      dataIndex: "experience",
      key: "experience",
      align: "center",
      render: (text, data) =>
        (data.min_salary_id == 1 && data.max_salary_id == 1 && "面议") || (
          <div>
            {data.min_salary || "暂无"} ~ {data.max_salary || "暂无"}
          </div>
        ),
    },
  ];

  const onSelectChange = (selectedRowKeys) => {
    // console.log(selectedRowKeys);
    setSelectedRowKeys(selectedRowKeys);
    form.setFieldsValue({
      resume_ids: [...selectedRowKeys],
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Modal
      getContainer={false}
      visible={visible}
      width={1150}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      maskClosable={false}
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>推荐人才</h1>
          </div>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 2 }}
            wrapperCol={{ span: 22 }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="resume_ids" label="选择简历" hidden>
                <Select mode="multiple" />
              </Form.Item>
              {/* <Form.Item name="resume_ids" label="选择简历">
                <Select
                  mode="multiple"
                  placeholder="请选择要推荐的简历"
                  options={all.map((item, key) => ({
                    label: item.name,
                    value: item.id,
                    key: key,
                    item: item,
                  }))}
                  style={{
                    width: "100%",
                  }}
                  // onChange={(e)=>{
                  //   console.log(e)
                  // }}
                />
              </Form.Item> */}
              <Form.Item name="interview_date_time" label="面试时间">
                <DatePicker showTime placeholder="请选择面试时间" />
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  justifyContent: "right",
                  marginBottom: "15px",
                }}
              >
                <Input.Search
                  placeholder="请输入要搜索的姓名关键词"
                  style={{ width: "250px" }}
                  onChange={(e) => {
                    setPagination({
                      ...pagination,
                      name: e.target.value,
                    });
                  }}
                  onSearch={(e) => {
                    console.log(e);
                    setDataSource([]);
                    setPagination({
                      ...pagination,
                      pageNo: 1,
                      total: 0,
                      name: e,
                    });
                    getList({
                      ...pagination,
                      pageNo: 1,
                      total: 0,
                      name: e,
                    });
                  }}
                />
              </div>
              <Table
                size="small"
                rowKey={"id"}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={dataSource}
                pagination={{
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  pageSizeOptions: [],
                }}
                onChange={paginationChange}
                virtual={true}
              />
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              推荐人才
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const Details = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({});
  const [chooseInfo, setChooseInfo] = useState({});
  const [recommendVisible, setRecommendVisible] = useState(false);

  useEffect(() => {
    console.log(props.match.params);
    getInfo(props.match.params.id);
  }, []);

  const getInfo = async (id) => {
    setLoading(true);
    const {
      data: { data, code },
    } = await GET_HALL_DETAILS({
      id: id,
    });

    if (code == 200) {
      setInfo(data);
      setLoading(false);
    }
  };

  const recommend = async () => {
    setRecommendVisible(true);
  };

  const onCreate = async (vals) => {
    console.log(vals);
    vals.user_enterprise_id = chooseInfo.user_enterprise_id;
    vals.job_id = chooseInfo.id;
    vals.interview_date_time = moment(vals.interview_date_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    vals.resume_ids = vals.resume_ids.join(",");
    // return;
    const {
      data: { data, code, message: msg },
    } = await POST_HALL_JOB(vals);

    if (code === 200) {
      message.success("推荐成功");
      setRecommendVisible(false);
      setChooseInfo(false);
    } else {
      message.error(msg);
    }
  };

  const addCollection = async (id) => {
    const {
      data: { data, code, message: msg },
    } = await ADD_HALL_COLLECT({
      ids: id + "",
    });

    if (code == 200) {
      message.success("收藏成功");
    } else {
      message.error(msg);
    }
  };

  const delCollection = async (id) => {
    let {
      data: { data, code, message: msg },
    } = await DEL_HALL_COLLECT({
      ids: id + "",
    });
    if (code == 200) {
      message.success("取消收藏");
    } else {
      message.error(msg);
    }
  };

  return (
    <div
      className="goods-storage order details"
      style={{
        backgroundColor: "transparent",
        padding: "0",
      }}
    >
      <div className="back">
        <Button
          type="link"
          icon={<RollbackOutlined />}
          style={{ color: "#333", padding: 0, marginBottom: "15px" }}
          onClick={() => {
            history.push("/index/order");
          }}
        >
          返回
        </Button>
      </div>
      <Row gutter={30}>
        <Col span={18}>
          {(loading && (
            <div className="p-5 text-center">
              <Spin size="large" />
            </div>
          )) || (
            <Card
              className="mb-3"
              title={
                <div className="title">
                  <div className="name mb-3">{info.title}</div>
                  <div className="author fs-12 mr-3">
                    {info.interview_contact} - {info.interview_phone}
                  </div>
                </div>
              }
              extra={
                <div className="extra">
                  <div className="mr-3">
                    {(info.min_salary_id == 1 &&
                      info.max_salary_id == 1 &&
                      "面议") ||
                      `${info.min_salary} ~ ${info.max_salary}`}
                  </div>
                </div>
              }
            >
              <CardBody>
                <div className="cont">
                  <div className="info-box">
                    <div className="info mb-3">
                      <span className="address mr-5">
                        {info.work_provinces}/{info.work_cities}/
                        {info.work_areas}- {info.work_address}
                      </span>
                      <span className="tips">{info.interview_time}</span>
                    </div>
                    <div className="intro mb-3">
                      <span>{info.education}</span>
                      <span className="mx-2">|</span>
                      <span>{info.sex_name}</span>
                      <span className="mx-2">|</span>
                      <span>{info.experience}</span>
                      <span className="mx-2">|</span>
                      <span>
                        {info.min_age}岁 ~ {info.max_age}岁
                      </span>
                      <span className="mx-2">|</span>
                      <span>需 {info.number} 人</span>
                    </div>
                    <div className="tip mb-3"></div>
                    <div className="tags">
                      {info?.benefits &&
                        info.benefits.split(",").map((item, key) => (
                          <Tag className="mr-2" color="orange" key={key}>
                            {item}
                          </Tag>
                        ))}
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <div style={{ fontSize: 20 }}>
                        <span>佣金：</span>
                        <span style={{ color: "red" }}>
                          {info.brokerage}人才币
                        </span>
                        /人
                      </div>
                      <div className="tip fs-14 mt-3">
                        佣金规则：企业确认“入职45天”状态后，HR即可获得相应佣金收益
                      </div>
                    </div>
                    <div>
                      <span className="mr-3">
                        剩余推荐位：{info.number_now}人
                      </span>
                      <Button
                        type="primary"
                        className="mr-3"
                        onClick={() => {
                          recommend();
                          setChooseInfo(info);
                        }}
                      >
                        推荐人才
                      </Button>

                      {(info.is_collect && (
                        <Button
                          type="primary"
                          ghost
                          danger
                          onClick={() => {
                            delCollection(info.id);
                          }}
                        >
                          取消收藏
                        </Button>
                      )) || (
                        <Button
                          type="primary"
                          ghost
                          onClick={() => {
                            addCollection(info.id);
                          }}
                        >
                          收藏职位
                        </Button>
                      )}
                    </div>
                  </div>
                  <hr />
                  <div className="desc-box">
                    <div className="page-header">
                      <div className="title">职位描述：</div>
                    </div>
                    <pre
                      className="desc fs-14 mt-3"
                      style={{ fontFamily: "微软雅黑" }}
                    >
                      {info.intro}
                    </pre>
                  </div>
                </div>
              </CardBody>
            </Card>
          )}
        </Col>
        <Col span={6}></Col>
      </Row>

      {/* 推荐 */}
      {recommendVisible && (
        <CreateShopForm
          visible={recommendVisible}
          onCreate={onCreate}
          onCancel={() => {
            setRecommendVisible(false);
          }}
        />
      )}
    </div>
  );
};

export default Details;
