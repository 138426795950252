import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  Route,
  Redirect,
  Link,
  useLocation,
  useHistory,
} from "react-router-dom";
import { Layout, Menu, Dropdown, Button } from "antd";
import api from "../../api";
import "./index.less";
import cookie from "react-cookies";

const { SubMenu } = Menu;

const ContHeader = () => {
  const history = useHistory();
  const [userinfo, setUserinfo] = useState({});

  const getUserInfo = async () => {
    let res = await api.userGetInfo();
    if (res.code == 200) {
      delete res.data.userPwd;
      // console.log(res.data)
      setUserinfo(res.data);
      localStorage.setItem("userInfo", JSON.stringify(res.data));
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <div className="cont-header">
      <div className="logo mr-5">
        <img src={require("./../../assets/image/logo.png").default} />
        <span>选择店铺</span>
      </div>

      <div className="userinfo">
        <div className="info">
          <div className="comp">{(userinfo && userinfo.mobile) || "-"}</div>
        </div>
        <div className="btn">
          <Button
            type="link"
            className="p-0"
            style={{ color: "#fff" }}
            onClick={() => {
              localStorage.removeItem("userInfo");
              cookie.remove("accessToken");
              cookie.remove("accessToken",{path: '/'});
              cookie.remove("accessToken",{path: '*'});
              cookie.remove("userInfo");
              cookie.remove("userInfo",{path: '/'});
              cookie.remove("userInfo",{path: '*'});
              history.push("/login");
            }}
          >
            退出
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ContHeader;
