import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Route, Redirect, Link, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Table,
  Form,
  InputNumber,
  Button,
  Modal,
  message,
} from "antd";
import cookie from "react-cookies";
import "./list.less";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { cp } from "fs";
import api from "../../api";
import {
  GET_USER_INFO,
  USER_EXCHANGE_BALANCE,
  USER_LOG,
} from "../../api/login";
import moment from "moment";
import ECharts from "echarts-for-react";
import * as echarts from "echarts";

const CreateShopForm = ({ visible, onCreate, onCancel }) => {
  const [form] = Form.useForm();
  const [formVals, setFormVals] = useState({});
  useEffect(async () => {
    form.resetFields();
  }, [visible]);

  return (
    <Modal
      getContainer={false}
      visible={visible}
      width={450}
      footer={false}
      okText={"保存"}
      cancelText={"取消"}
      onOk={() => {
        onCreate(form.getFieldsValue());
      }}
      onCancel={onCancel}
      destroyOnClose
      className="modal-createshop"
      centered
    >
      <Row gutter={30}>
        <Col span={24}>
          <div className="title">
            <h1>申请提现</h1>
          </div>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name="form_in_modal"
            onValuesChange={(vals, allVals) => {
              setFormVals(allVals);
            }}
          >
            <div>
              <Form.Item name="balance" label="申请金额">
                <InputNumber
                  placeholder="请输入有效申请金额"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </div>
          </Form>
          <div className="mt-3">
            <Button
              type="primary"
              size="large"
              className="py-3 px-5"
              style={{ height: "auto", lineHeight: "1" }}
              onClick={() => {
                onCreate(form.getFieldsValue());
              }}
            >
              申请提现
            </Button>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

const List = () => {
  const [userInfo, setUserInfo] = useState({});
  const [visibleCreate, setVisibleCreate] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 10,
    pageNo: 1,
    total: 0,
  });
  const [dataSource, setDataSource] = useState([]);
  const columns = [
    {
      title: "人才币",
      dataIndex: "balance",
      key: "balance",
      render: (text, data) => (data.balance_type == 1 && `+ ${text}`) || `- ${text}`,
    },
    {
      title: "类型",
      dataIndex: "balance_type",
      key: "balance_type",
      render: (text, data) => (text == 1 && "收入") || "支出",
    },
    {
      title: "明细",
      dataIndex: "text",
      key: "text",
      render: (text, data) => `【${data.tag_name}】 ${data.text}`,
    },
    {
      title: "创建时间",
      dataIndex: "create_time",
      key: "create_time",
    },
  ];
  const getList = async (paginations) => {
    const {
      data: { data, code, message: msg },
    } = await USER_LOG({ ...paginations });
    if (code == 200) {
      setDataSource(data.data);
      setPagination({
        ...pagination,
        total: data.total,
      });
    }
  };
  const paginationChange = (pages) => {
    setPagination({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    getList({
      ...pagination,
      pageNo: pages.current || 1,
      current: pages.current,
    });
    console.log("Page: ", pages);
  };

  const getUserInfo = async () => {
    const {
      data: { code, data, message: msg },
    } = await GET_USER_INFO();
    if (200 == code) {
      setUserInfo(data);
    }
  };

  const onCreate = async (values) => {
    // console.log(values);
    // return;
    let {
      data: { code, data, message: msg },
    } = await USER_EXCHANGE_BALANCE(values);
    if (code == 200) {
      // message.success("申请已提交，请等待官方人员审核");
      message.success(msg);
    } else {
      message.error(msg);
    }
    14555;
    getList();
    setVisibleCreate(false);
  };

  useEffect(() => {
    getUserInfo();
    getList(pagination);
  }, []);

  return (
    <div className="goods-storage">
      <Row>
        <Col span={12}>
          <div className="intro">
            <div className="intro-item mb-2">姓名：{userInfo.name}</div>
            <div className="intro-item">手机号：{userInfo.mobile}</div>
          </div>
        </Col>
        <Col span={12}>
          <div className="balance mb-3" style={{ fontSize: 24 }}>
            余额: {userInfo.balance}人才币
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                setVisibleCreate(true);
              }}
            >
              提现
            </Button>
          </div>
        </Col>
      </Row>
      <div className="mt-3">
        <div className="page-header mb-3">
          <div className="title">收支明细</div>
        </div>
        <div className="table">
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={{
              pageSize: pagination.pageSize,
              total: pagination.total,
            }}
            onChange={paginationChange}
          />
        </div>
      </div>

      {/* 提现申请 */}
      {visibleCreate && (
        <CreateShopForm
          visible={visibleCreate}
          onCreate={onCreate}
          onCancel={() => {
            setVisibleCreate(false);
          }}
        />
      )}
    </div>
  );
};

export default List;
