import React from "react";
import { Link } from "react-router-dom";
import { Form, Input, Button, Col, Row, Spin, Modal } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import "./login.less";
import { LOGIN } from "../../api/login";
import cookie from "react-cookies";
import uitls from "../../assets/js/public";
import { message } from "antd";

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props)
    this.state = {
      loading: false,
    };
  }

  componentDidMount = () => {
    document.title = "七朵云";

    if (!!cookie.load("accessToken")) {
      this.props.history.push("/index/index");
    }
  };

  login = async (values) => {
    this.setState({ loading: true });
    values = Object.assign(values);
    let {
      data: { data, code, message: msg },
    } = await LOGIN(values);

    if (code === 200) {
      message.success("登录成功");
      cookie.save("accessToken", data);

      this.props.history.push("/index/index");
      return;
    }
    message.error(msg);
    this.setState({ loading: false });

    //   // test
    //     message.success("登录成功");
    //     this.props.history.push("/index");
    //     return;
  };

  render() {
    return (
      <div className="login-cont">
        <div className="copyright">
          <p>
            Copyright © 2022 速猎直推
            <a href="https://beian.miit.gov.cn/" target="_blank">
              沪ICP备2021024285号-1
            </a>
          </p>
        </div>
        <div className="login-sign">
          <div className="title">
            <h1>登录</h1>
            <p>
              没有账号？
              <Link to="/register">注册</Link>
            </p>
          </div>
          <Form
            className="login-form"
            onFinish={this.login}
            name="normal_login"
            initialValues={{ remember: true }}
          >
            <Form.Item
              name="mobile"
              rules={[{ required: true, message: "请输入账号" }]}
            >
              <Input
                size="large"
                allowClear
                prefix={
                  <UserOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                placeholder="请输入账号"
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: "请输入密码" }]}
            >
              <Input.Password
                size="large"
                allowClear
                prefix={
                  <LockOutlined
                    style={{
                      color: "rgba(0,0,0,.25)",
                      fontSize: "18px",
                    }}
                  />
                }
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                placeholder="请输入密码"
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                size="large"
                style={{ fontSize: "14px", borderRadius: "5px" }}
                block
              >
                立即登录
              </Button>
              <div style={{ color: "rgba(0, 0, 0, 0.45)", marginTop: "5px" }}>
                忘记密码？
                <Link to="/forget">找回</Link>
              </div>

              <Button
                href="https://www.slzhitui.com/"
                className="login-form-button mt-3"
                size="large"
                style={{ fontSize: "14px", borderRadius: "5px" }}
                block
              >
                返回首页
              </Button>
            </Form.Item>
            <div className="form-agreement">
              了解
              <a href="https://www.slzhitui.com/deal" target="_blank">
                《用户服务协议》
              </a>
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
