import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import { Spin, Layout, Card, Menu, Button, Dropdown } from "antd";
import {
  DownOutlined,
  BarChartOutlined,
  CloudOutlined,
  ShopOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import api from "../api";
import { GET_USER_INFO } from "../api/login";
import cookie from "react-cookies";
import "./index.less";

import ContHeader from "../components/ContHeader";
import ContBreadcrumb from "../components/ContBreadcrumb";
//公共函数
import utils from "../assets/js/public";
import basicRouters from "../assets/json/basicRouters.json";

//页面引入
import Overview from "./overview/list.js"; //概览

import Order from "./order/list"; // 接单大厅
import OrderDetails from "./order/details"; // 接单大厅详情
import Resume from "./resume/list"; // 人才简历库
import Collection from "./collection/list";
import Recommend from "./recommend/list";
import My from "./my/list";

import PageList from "./page/list"; //页面列表

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Minishop extends React.Component {
  state = {
    loading: false,
    user_data: "",
    mode: "inline",
    theme: "light",
    routerList: [],
    routerListChild: [],
    ruleList: [],
    shopInfo: {},
    userInfo: {},
  };
  componentWillUnmount() {
    this.setState = () => false;
  }
  async componentDidUpdate() {
    await this.isLogin();
  }

  componentDidMount = async () => {
    await this.isLogin();
    await this.getUserInfo();
  };
  async isLogin() {
    if (!cookie.load("accessToken")) {
      cookie.remove("accessToken", { path: "/" });
      localStorage.removeItem("userInfo");
      this.props.history.push("/login");
      return true;
    }
    return false;
  }
  getUserInfo = async () => {
    let res = await GET_USER_INFO();
    if (res.status == 200) {
      // console.log(res.data.data)
      this.setState({
        userInfo: res.data.data,
      });
      localStorage.setItem("userInfo", JSON.stringify(res.data.data));
    }
  };
  render() {
    const { loading, shopInfo, userInfo } = this.state;
    return (
      <Spin spinning={loading}>
        <Layout hasSider>
          <Sider
            style={{
              overflow: "auto",
              height: "100vh",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <div
              className="logo"
              style={{ padding: "15px 0", textAlign: "center" }}
            >
              <img
                src={require("../assets/image/logo.png").default}
                style={{ width: 120 }}
              />
            </div>

            <Menu theme="dark" mode="inline" defaultSelectedKeys={["2"]}>
              {/* <Menu.Item key="1" icon={<UserOutlined />}>
                <Link to="/index/overview">首页</Link>
              </Menu.Item> */}
              <Menu.Item key="2" icon={<UserOutlined />}>
                <Link to="/index/order">接单大厅</Link>
              </Menu.Item>
              <Menu.Item key="3" icon={<UserOutlined />}>
                <Link to="/index/resume">人才简历库</Link>
              </Menu.Item>
              <Menu.Item key="4" icon={<UserOutlined />}>
                <Link to="/index/collection">职位收藏夹</Link>
              </Menu.Item>
              <Menu.Item key="5" icon={<UserOutlined />}>
                <Link to="/index/recommend">我的推荐</Link>
              </Menu.Item>
              <Menu.Item key="6" icon={<UserOutlined />}>
                <Link to="/index/my">个人中心</Link>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout className="site-layout" style={{ marginLeft: 200 }}>
            <Header
              className="site-layout-background"
              style={{ padding: 0, background: "#fff" }}
            >
              <div className="d-flex justify-content-between align-item-center px-3">
                <div></div>
                <div>
                  <Dropdown
                    overlay={
                      <Menu className="minishop-head-dropdown">
                        <Menu.Item>
                          <Button
                            type="link"
                            className="p-0 text-gray"
                            onClick={() => {
                              localStorage.removeItem("userInfo");
                              cookie.remove("accessToken");
                              cookie.remove("accessToken", { path: "/" });
                              cookie.remove("accessToken", { path: "*" });
                              cookie.remove("userInfo");
                              cookie.remove("userInfo", { path: "/" });
                              cookie.remove("userInfo", { path: "*" });
                              this.props.history.push("/login");
                            }}
                          >
                            退出
                          </Button>
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <div style={{ cursor: "pointer" }}>
                      {(userInfo && "你好，" + userInfo.name) || "-"}
                    </div>
                  </Dropdown>
                </div>
              </div>
            </Header>
            <Content style={{ margin: "24px 16px 0", overflow: "initial" }}>
              <Switch>
                {/* 概览 */}
                {/* <Route path="/index/overview" exact component={Overview} /> */}
                {/* 接单大厅 */}
                <Route path="/index/order" exact component={Order} />
                <Route
                  path="/index/order/details/:id"
                  exact
                  component={OrderDetails}
                />
                {/* 人才简历库 */}
                <Route path="/index/resume" exact component={Resume} />
                {/* 收藏 */}
                <Route path="/index/collection" exact component={Collection} />
                {/* 推荐 */}
                <Route path="/index/recommend" exact component={Recommend} />
                {/* 个人中心 */}
                <Route path="/index/my" exact component={My} />

                <Redirect to={`/index/order`} />
              </Switch>
            </Content>
            <Footer style={{ textAlign: "center", background: "transparent" }}>
              Copyright © 2022 速猎直推
              <a href="https://beian.miit.gov.cn/" target="_blank">
                沪ICP备2021024285号-1
              </a>
            </Footer>
          </Layout>
        </Layout>
      </Spin>
    );
  }
}

export default Minishop;
