import instance from "./instance.js";
import { base } from "./config.js";
import { message } from "antd";
import qs from "qs";
export default {
  // 添加定时任务
  async cronAdd(params) {
    return await instance
      .post(`${base}/client/cron/add`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 删除定时任务
  async cronDelete(params) {
    return await instance
      .delete(`${base}/client/cron/delete`, { data: params })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 获取定时任务列表
  async cronList(params) {
    return await instance
      .get(`${base}/client/cron/list?${qs.stringify(params)}`)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 开启定时任务
  async cronStart(params) {
    return await instance
      .post(`${base}/client/cron/start`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 暂停定时任务
  async cronStop(params) {
    return await instance
      .post(`${base}/client/cron/stop`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
  // 更新定时任务
  async cronUpdate(params) {
    return await instance
      .post(`${base}/client/cron/update`, params)
      .then((res) => {
        return res;
      })
      .catch((error) => {
        message.error("服务器出错");
      });
  },
};
